import "./Tiles.css";
import TandCfooter from "./TandCfooter"
import {taggingHelper} from '../helpers/taggingHelper'

const CutpriceTile = ({
  bgImage,
  sku: productSku,
  headerColor = "red",
  largeTile,
  swipeable,
  index,
  dragging,
  sliderID,
  swipeDirection,
  sliderLength,
  pageUrl,
  info: {
    productUrl,
    shortDescription,
    price,
    custom_title,
    heading,
    tnc,
    symbol,
    promoPrice,
    show_bonus_image,
    overlay_image,
    pk,
    categories,
    
  },
}) => {
  //promoPrice && promoPrice !== undefined && console.log('CutpriceTile promoPrice:', promoPrice, promoPrice.ticketSlashedPrice )
  const imageUrl = `https://thegoodguys.sirv.com/Content/WhiteTilesSKUs_New/${productSku}.png?w=280&q=50`;
  const wasPrice = promoPrice && promoPrice !== undefined && promoPrice.ticketSlashedPrice 
  const salePrice = wasPrice && (wasPrice - price);
  const procesed_symbol = symbol ? symbol : "¤";
  //swipeable && swipeDirection !== undefined ? console.log('swipeDirection:',swipeDirection) : console.log('direction is undefined')
  const reducedLink = productUrl ? productUrl.slice(26) : null;

  return (
    <div 
    className={`whitetiles-card__wrapper border-all border-graylight bg-white marg-5 pos-relative unselectable ${
      swipeable ? "marg-right-20 marg-left-20" : ""}`} 
      onTouchEnd={() => {
        //console.log('onTouchEnd event triggered Cutprice', `Swipe Direction ${swipeDirection}`);
        if (swipeable && swipeDirection !== undefined) {
          taggingHelper(
            `Swipe Direction ${swipeDirection}`,
            swipeDirection === 'Left' 
              ? (index + 1 === sliderLength ? '1' : index + 2)
              : (index  <= 0 ? sliderLength : index ),
            sliderID,
            'type1'
          );
        }
      }}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <a
        href={productUrl}
        onClick={(e) => {
          dragging ? e.preventDefault() : taggingHelper(pageUrl, reducedLink, index+1,sliderID)}}
        className={`home-white-tile unselectable ${
        largeTile ? "home-white-tile__inner" : "home-white-tile__innerSmall"}`}
        >
        <section className="body-tile-copy unselectable">
          {
          // custom_title && custom_title !== "" ? (
          //   <header
          //     className="home-white-tile-header marg-top-10 unselectable"
          //     style={
          //       headerColor !== ""
          //         ? { backgroundColor: `${headerColor}` }
          //         : { backgroundColor: "red" }
          //     }
          //   >
          //     {custom_title}
          //     <sup>{symbol}</sup>
          //   </header>
          // ) : 
          (
            <header
              className="home-white-tile-header marg-top-10 unselectable"
              style={
                headerColor !== ""
                  ? { backgroundColor: `${headerColor}` }
                  : { backgroundColor: "red" }
              }
            >
              ${salePrice} OFF<sup>{procesed_symbol}</sup>
            </header>
          )}
          <div className="clearfix marg-top-5 unselectable">
            <div className="home-white-tile__left-col marg-top-0 unselectable">
              <img
                src={imageUrl}
                alt={heading}
                className="img-responsive padd-top-5 lazy unselectable"
                width="280"
                height="372"
                draggable="false"
              />
            </div>
            <div className="home-white-tile__right-col text-left unselectable">
              <p className="home-white-tile__product-name text-black text-medium line-height-xs padd-top-5 unselectable">
                {shortDescription}
              </p>
              <div className="home-white-tile__price-lock-up unselectable">
              {promoPrice && promoPrice.promoPriceType === "BLACK FRIDAY" ? (
                <div className="c-cut-price c-cut-price--black home-white-tile__cut-price unselectable">
                  <span className="c-cut-price__ticket-price unselectable">
                    <svg className="c-cut-price__ticket-svg pos-absolute" fill="#fff" xmlns="http://www.w3.org/2000/svg" width="62.946" height="8.983" viewBox="0 0 62.946 8.983">
                      <path d="M4.914,2.236V.156H0v2.08H1.287V8.827h2.34V2.236ZM7.865,8.827V.156H5.525V8.827Zm6.084-2.665a1.762,1.762,0,0,1-1.222.559c-1.2,0-1.482-1.274-1.482-2.236,0-.988.286-2.314,1.521-2.314a1.394,1.394,0,0,1,1.079.52V.221A2.52,2.52,0,0,0,12.688,0C10.075,0,8.853,2.145,8.853,4.524c0,2.21,1.066,4.459,3.562,4.459a3.026,3.026,0,0,0,1.391-.312Zm7.462,2.665-2.08-4.485L21.177.156h-2.47L17.329,4.027,17.394.153h-2.34V8.827h2.34L17.329,4.68l1.56,4.147Zm5.018,0V6.747h-2V5.421h1.794V3.458h-1.8V2.2h2V.156h-4.34V8.827Zm5.707-6.591V.156H27.222v2.08h1.287V8.827h2.34V2.236Z" data-name="Path 6565"/>
                    </svg>
                    <span className="c-cut-price__ticket-price-symbol">
                      <sup>{procesed_symbol}</sup>
                    </span>
                    <span className="c-cut-price__old-price">&#36;{wasPrice}</span>
                  </span>
                  {pk === true ? (
                    <span className="c-cut-price__price">
                      <sup>$</sup>
                      {price}
                      <span className="text-size-75">pk</span>
                    </span>
                  ) : (
                    <span className="c-cut-price__price">
                      <sup>$</sup>
                      {price}
                    </span>
                  )}
                </div>
              ) : (
                <div className="c-cut-price home-white-tile__cut-price unselectable">
                  <span className="c-cut-price__ticket-price unselectable">
                  <svg class="c-cut-price__ticket-svg margin-left-10 pos-absolute" xmlns="http://www.w3.org/2000/svg" width="62.946"  height="8.986" viewBox="0 0 10.136 8.986"><path d="M4.914,2.236V.156H0v2.08H1.287V8.827h2.34V2.236ZM7.865,8.827V.156H5.525V8.827Zm6.084-2.665a1.762,1.762,0,0,1-1.222.559c-1.2,0-1.482-1.274-1.482-2.236,0-.988.286-2.314,1.521-2.314a1.394,1.394,0,0,1,1.079.52V.221A2.52,2.52,0,0,0,12.688,0C10.075,0,8.853,2.145,8.853,4.524c0,2.21,1.066,4.459,3.562,4.459a3.026,3.026,0,0,0,1.391-.312Zm7.462,2.665-2.08-4.485L21.177.156h-2.47L17.329,4.027,17.394.153h-2.34V8.827h2.34L17.329,4.68l1.56,4.147Zm5.018,0V6.747h-2V5.421h1.794V3.458h-1.8V2.2h2V.156h-4.34V8.827Zm5.707-6.591V.156H27.222v2.08h1.287V8.827h2.34V2.236Z" transform="translate(0 0.003)"/></svg>
                    <span className="c-cut-price__ticket-price-symbol">
                      <sup>{procesed_symbol}</sup>
                    </span>
                    <span className="c-cut-price__old-price">&#36;{wasPrice}</span>
                  </span>
                  {pk === true ? (
                    <span className="c-cut-price__price">
                      <sup>$</sup>
                      {price}
                      <span className="text-size-75">pk</span>
                    </span>
                  ) : (
                    <span className="c-cut-price__price">
                      <sup>$</sup>
                      {price}
                    </span>
                  )}
                </div>
              )}
                {show_bonus_image === true ? (
                  <div className="home-white-tile__extra-img unselectable">
                    <img
                      alt="bonus"
                      className=" img-responsive lazy"
                      src="https://thegoodguys.sirv.com/dm/homepage-acoustic-images/White%20Tiles/bonus-2x.png?q=50"
                    />
                  </div>
                ) : (
                  <div className="home-white-tile__extra-img-empty"></div>
                )}
              </div>
            </div>
          </div>
          {overlay_image !== "" ? (
            <img
              alt="The Good Guys Sale"
              className="home-white-tile_overlay-img img-responsive lazy pos-absolute unselectable"
              src={overlay_image}
              width="60"
            />
          ) : null}
        </section>
        {tnc !== "" ? <TandCfooter info={tnc} /> : null}
      </a>
      <input hidden className="white_tile_cat_l1" data-wt-cat={categories && categories.l1 && categories.l1.name} />
      <input hidden className="white_tile_cat_l2" data-wt-cat={categories && categories.l2 && categories.l2.name} />
      <input hidden className="white_tile_cat_l3" data-wt-cat={categories && categories.l3 && categories.l3.name} />
    </div>
  );
};

export default CutpriceTile;
