export const taggingHelper = (url, targetUrl, index,id) => {

    window.dataLayer.push({
      event: "customer_interactions",
      type : `${url}`,
      category: "White Tiles" ,
      action: "Tile Click",
      click_url : `${targetUrl}`,
      position : `${index}, SliderID:${id}`

    });
  };
  export const taggingDotsHelper = (url, index,sliderId) => {

    window.dataLayer.push({
      event: "customer_interactions",
      type : `${url}`,
      category: "White Tiles" ,
      action: "Navigation Dots",
      position : `${index}, SliderID:${sliderId}`

    });
  };
  export const taggingArrowsHelper = (url,direction, index,sliderId) => {
    window.dataLayer.push({
      event: "customer_interactions",
      type : `${url}`,
      category: "White Tiles" ,
      action: `Arrow Click ${direction}`,
      position : `${index}, SliderID:${sliderId}`

    });
  };




  