import { useEffect,useState } from "react";
import useGetProductsInfo from "../hooks/useGetProductsInfo";
import WhiteTilesSlier from "./WhiteTilesSlider";
import Spinner from "../UI/Spinner";
import _ from "lodash"
import WhiteTilePlaceholder from '../UI/WhiteTilePlaceHolder'

const WhiteTileWrapper = (props) => {
  const [deviceWidth, setDeviceWidth] = useState(() => window.innerWidth)
  //console.log('props.skuArr:',props.skuArr)
  const { getProductInfoFunc, productsInfo, isLoadingProductInfo } =
  useGetProductsInfo();
  const { wrapperInfo, skuArr, skusInfo, pageUrl , rowNumber} = props;

  const homepageData = wrapperInfo || null;
  const bgImage = homepageData?.background_image || null;
  const headerColor = homepageData?.header_bg_color || null;
  const categoryBtnText = homepageData?.category_btn.text || null;
  const categoryBtnBgColour = homepageData?.category_btn.bg_colour || null;
  const categoryBtnBgHoverColour = homepageData?.category_btn.bg_hover_colour || null;
 
  const filteredNumSKus = skuArr.filter(Number);
  const filteredNanSKus = skuArr.filter(isNaN);
  const skuArr_string = filteredNumSKus.toString().split(",");

  //console.log('filteredNumSKus:',filteredNumSKus,'filteredNanSKus:',filteredNanSKus,filteredNanSKus.length,'productsInfo:',productsInfo)
  
//console.log('rowNumber in wrapper:',rowNumber)
  useEffect(() => {
    getProductInfoFunc(skuArr_string);
    const handleResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const autoplay = deviceWidth < 768 ? false : true
  const swipeable = deviceWidth < 768 ? true : false
  const dragable = deviceWidth < 1024 ? true : false

  const defualtData = { sku: "10000000" };
  let categoryArr = [];
  if (
    productsInfo &&
    productsInfo !== undefined &&
    filteredNumSKus.length > 0 &&
    filteredNanSKus.length > 0
  ) {
    filteredNanSKus.forEach(() => {
      //productsInfo.push(defualtData);
      categoryArr.push(defualtData)
    });
  } else if (filteredNumSKus.length === 0 && filteredNanSKus.length > 0) {
    //console.log('just category')
    filteredNanSKus.forEach(() => {
      categoryArr.push(defualtData);
    });
  }

  //console.log('productsInfo with default data :',productsInfo,'categoryArr:',categoryArr,categoryArr.length)

  const filteredSkuInfo = skusInfo.filter((info) => {
      return skuArr.indexOf(info.sku) > -1 && info.assigned_page.indexOf(pageUrl) > -1 
  });

 // console.log('filteredSkuInfo:',filteredSkuInfo)

  let combinedData;
 
  let sortingFunction
  if(productsInfo && productsInfo !== undefined && categoryArr !== undefined && categoryArr.length === 0){
    sortingFunction = productsInfo.sort((a, b) => {
      const indexA = filteredSkuInfo.findIndex((item) => item.sku == a.sku);
      const indexB = filteredSkuInfo.findIndex((item) => item.sku == b.sku);
      return indexA - indexB;
    });
  }
//console.log('productsInfo for checking:',productsInfo)


let arr_01 = []
let arr_02 = []

if(productsInfo && productsInfo !== undefined){
  filteredSkuInfo.map((item) => {
   productsInfo.map((el) => {
      if(item.sku == el.sku){
        arr_01.push(el)
        arr_02.push(item)
      }
    });
 })
  }else {
    combinedData = filteredSkuInfo.map((item, i) =>
        Object.assign({}, item, categoryArr[i])
      )
  }
    const merged = _.merge(_.keyBy(arr_01, 'sku'), _.keyBy(arr_02, 'sku'));
    const values = _.values(merged);
    const values_sku = values.map(item => item.sku)
    const arr_03 = filteredSkuInfo.filter(item => !values_sku.includes(item.sku));
      combinedData =  arr_03.concat(values)
 
  //console.log('ARRAYS:',arr_01,arr_02,arr_03,'combinedData with arrays:',combinedData)

  if (productsInfo && productsInfo !== undefined && combinedData && combinedData !== undefined) {
    //console.log('have both')
    combinedData.sort((a, b) => {
      return a.position - b.position;
    });
  } else if (categoryArr !== undefined && categoryArr.length > 0 && combinedData && combinedData !== undefined) {
    //console.log('just c')
    combinedData.sort((a, b) => {
      return a.position - b.position;
    });
  }
  
  let productsInfo_row1;
  let productsInfo_row2;
  let productsInfo_row3;
  let productsInfo_row4;
  let overlayImage_arr = [];
  if (
    combinedData && combinedData !== null){
      combinedData.map(d => {
        //console.log('d:',d)
        if (d.overlay_image !== "" || d.tnc !== "") {
          overlayImage_arr.push(d);
        }
       
      })
    }
  const largeTile = overlayImage_arr.length > 0;

  if (combinedData && combinedData.length > 3) {

    if (combinedData.length < 9) {
      productsInfo_row1 = combinedData
      productsInfo_row2 = null;

    } else if (combinedData.length < 17) {
      
      productsInfo_row1 = combinedData.slice(0,Math.ceil(combinedData.length / 2));
      
      productsInfo_row2 = combinedData.filter( function( el ) {
        return productsInfo_row1.indexOf( el ) < 0;
      })

    } else if (combinedData.length < 32) {
      productsInfo_row1 = combinedData.slice(0,Math.ceil(combinedData.length / 3));

      productsInfo_row2 = combinedData.slice(
        productsInfo_row1.length,
        Math.ceil((combinedData.length - productsInfo_row1.length) / 2)
        + productsInfo_row1.length
      );

      productsInfo_row3 = combinedData.slice(
        productsInfo_row1.length + productsInfo_row2.length,
        combinedData.length
      );
    } else {
      productsInfo_row1 = combinedData.slice(0,Math.ceil(combinedData.length / 4));

      productsInfo_row2 = combinedData.slice(
        productsInfo_row1.length,
        Math.ceil((combinedData.length - productsInfo_row1.length) / 3)
        + productsInfo_row1.length
      );

      productsInfo_row3 = combinedData.slice(
        productsInfo_row1.length + productsInfo_row2.length,
        Math.ceil((combinedData.length - productsInfo_row1.length - productsInfo_row2.length) / 2)
        + productsInfo_row1.length + productsInfo_row2.length
      );

      productsInfo_row4 = combinedData.slice(
        productsInfo_row1.length + productsInfo_row2.length + productsInfo_row3.length,
        combinedData.length
      );
    }
  }
  //console.log('productsInfo_top:',productsInfo_top,'productsInfo_bottom:',productsInfo_bottom)
  let showArrows
  // console.log({homepageData});
  if(combinedData && combinedData !== undefined)
  { showArrows = combinedData.length > 4 }
  return isLoadingProductInfo ? (
    <>
    <Spinner />
    <WhiteTilePlaceholder pageUrl={pageUrl} rowNumber={rowNumber}/>
    </>
    
  ) : combinedData && combinedData !== undefined && combinedData.length > 3 ? (
    <div className="ggds">
      <div className="whiteTiles__container fullwidth marg-bottom-20 pos-relative container padd-0">
        <div className="whiteTiles__inner">
          <nav>
            {homepageData && homepageData.slider_title !== "" &&
              <h2
                className="text-center text-bold text-size-175 marg-bottom-10 line-height-sml"
                style={
                  homepageData.title_color !== ""
                    ? { color: homepageData.title_color }
                    : null
                }
              >
                {homepageData.slider_title}
              </h2>
            }
            <WhiteTilesSlier
              productsInfo={productsInfo_row1}
              bgImage={bgImage}
              headerColor={headerColor}
              categoryBtnText={categoryBtnText}
              categoryBtnBgColour={categoryBtnBgColour}
              categoryBtnBgHoverColour={categoryBtnBgHoverColour}
              autoPlaySpeed="3500"
              showArrows={showArrows}
              largeTile={largeTile}
              autoplay={autoplay}
              swipeable={swipeable}
              dragable={dragable}
              pageUrl={pageUrl}
              sliderID ='1'
            />
          </nav>
          
          <nav>
            {homepageData && homepageData.slider_title_2 !== "" &&
              <p
                className="text-center text-bold text-size-175 marg-top-10 marg-bottom-10 line-height-sml"
                style={
                  homepageData.title_color !== ""
                    ? { color: homepageData.title_color }
                    : null
                }
              >
                {homepageData.slider_title_2}
              </p>
            }
            <WhiteTilesSlier
              productsInfo={productsInfo_row2}
              bgImage={bgImage}
              headerColor={headerColor}
              categoryBtnText={categoryBtnText}
              categoryBtnBgColour={categoryBtnBgColour}
              categoryBtnBgHoverColour={categoryBtnBgHoverColour}
              autoPlaySpeed="4000"
              showArrows={showArrows}
              largeTile={largeTile}
              autoplay={autoplay}
              swipeable={swipeable}
              dragable={dragable}
              pageUrl={pageUrl}
              sliderID ='2'
            />
          </nav>

          <nav>
            {homepageData && homepageData.slider_title_3 !== "" &&
              <p
                className="text-center text-bold text-size-175 marg-top-10 marg-bottom-10 line-height-sml"
                style={
                  homepageData.title_color !== ""
                    ? { color: homepageData.title_color }
                    : null
                }
              >
                {homepageData.slider_title_3}
              </p>
            }
            <WhiteTilesSlier
              productsInfo={productsInfo_row3}
              bgImage={bgImage}
              headerColor={headerColor}
              categoryBtnText={categoryBtnText}
              categoryBtnBgColour={categoryBtnBgColour}
              categoryBtnBgHoverColour={categoryBtnBgHoverColour}
              autoPlaySpeed="4500"
              showArrows={showArrows}
              largeTile={largeTile}
              autoplay={autoplay}
              swipeable={swipeable}
              dragable={dragable}
              pageUrl={pageUrl}
              sliderID ='3'
            />
            <WhiteTilesSlier
              productsInfo={productsInfo_row4}
              bgImage={bgImage}
              headerColor={headerColor}
              autoPlaySpeed="4500"
              showArrows={showArrows}
              largeTile={largeTile}
              autoplay={autoplay}
              swipeable={swipeable}
              dragable={dragable}
              pageUrl={pageUrl}
              sliderID ='4'
            />
          </nav>
        </div>
      </div>
    </div>
  ) : null;
};

export default WhiteTileWrapper;
