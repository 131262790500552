import "./Tiles.css";
import TandCfooter from "./TandCfooter"
import {taggingHelper} from '../helpers/taggingHelper'

/** @jsxImportSource  @emotion/react */;
import { css } from '@emotion/react'

const CategoryTile = ({
  info: {
    category_title,
    category_link,
    category_image,
    category_description,
    category_secondary_text,
    tnc,
    symbol,
    overlay_image,
  },
  bgImage,
  headerColor,
  categoryBtnText,
  categoryBtnBgColour,
  categoryBtnBgHoverColour,
  largeTile,
  swipeable,
  index,
  dragging,
  sliderID,
  swipeDirection,
  sliderLength,
  pageUrl
}) => {
  // console.log('CategoryTile props:',category_title,category_link,category_image,category_description,category_secondary_text,tnc,symbol,
  // overlay_image)
  //swipeable && swipeDirection !== undefined ? console.log('swipeDirection:',swipeDirection) : console.log('direction is undefined')
  const reducedLink = category_link ? category_link.slice(26) : null;
  
  let btn = categoryBtnBgColour ?
      <span className="btn"
        css={css`
          background-color: ${categoryBtnBgColour} !important;
          border-color: ${categoryBtnBgColour} !important;

          &:hover {
            background-color: ${categoryBtnBgHoverColour} !important;
            border-color: ${categoryBtnBgHoverColour} !important;
          }
        `}
      >{categoryBtnText}</span>
  : <span className="btn btn-primary">{categoryBtnText}</span>;

  return (
    <div
    className={`whitetiles-card__wrapper border-all border-graylight bg-white marg-5 pos-relative unselectable ${
      swipeable ? "marg-right-20 marg-left-20" : ""}`} 
      onTouchEnd={() => {
        //console.log('onTouchEnd event triggered Category', `Swipe Direction ${swipeDirection}`);
        if (swipeable && swipeDirection !== undefined) {
          taggingHelper(
            `Swipe Direction ${swipeDirection}`,
            swipeDirection === 'Left' 
              ? (index + 1 === sliderLength ? '1' : index + 2)
              : (index  <= 0 ? sliderLength : index ),
            sliderID,
            'type1'
          );
        }
      }}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <a
        href={category_link}
        onClick={(e) => {
          dragging ? e.preventDefault() : taggingHelper(pageUrl, reducedLink,index+1,sliderID)}}
        className={`home-white-tile unselectable ${
          largeTile ? "home-white-tile__inner" : "home-white-tile__innerSmall"}`}
          >
        <section className="body-tile-copy unselectable">
          <header
            className="home-white-tile-header marg-top-10 unselectable"
            style={
              headerColor !== ""
                ? { backgroundColor: `${headerColor}` }
                : { backgroundColor: "red" }
            }
          >
            {category_title}
            <sup>{symbol}</sup>
          </header>
          <div className="clearfix marg-top-5 unselectable">
            <p className="home-white-tile__no-price-header text-black text-center text-upper marg-top-5 text-extra-bold text-size-150 line-height-xs unselectable">
              {category_description}
            </p>
            <p className="marg-top-5 text-black text-center text-size-110 line-height-1 unselectable">
              {category_secondary_text}
            </p>
            <img
              alt={category_title}
              className="img-responsive lazy unselectable"
              loading="lazy"
              src={category_image + "?w=542&q=50"}
              draggable="false"
            />
          </div>
          {overlay_image !== "" ? (
            <img
              alt="The Good Guys Sale"
              className="home-white-tile_overlay-img img-responsive lazy pos-absolute unselectable"
              src={overlay_image}
            />
          ) : null}
          {categoryBtnText ? ( 
            <span className="home-white-tile__category-btn-wrap pos-absolute text-center">
              {btn}
            </span>
          ) : null}
           
        </section>
        {tnc !== "" ? <TandCfooter info={tnc} /> : null}
      </a>
    </div>
  );
};

export default CategoryTile;
