import { svgCode } from '../UI/spinnerSvg'
import './WhiteTilePlaceHolder.css'  

const WhiteTilePlaceholder = ({rowNumber}) => {
 
//console.log('rowNumber',rowNumber)
  const renderPlaceholderItems = () => {
    
    const items = [];
    let rowCount 
    if (window.innerWidth > 1023){
      rowCount = 4
    }else if ( 767 < window.innerWidth  &&  window.innerWidth < 1024){
      rowCount = 3
    }else if (425 < window.innerWidth  && window.innerWidth < 768) {
      rowCount = 2
    }else if (window.innerWidth < 426 ) {
      rowCount = 1
    }
    const colCount = rowNumber ? rowNumber : 3

    //console.log('rowCount:',rowCount,window.innerWidth)

    for (let i = 0; i < rowCount; i++) {
      const rowItems = [];
      for (let j = 0; j < colCount; j++) {
        rowItems.push(
          <div key={`${i}-${j}`} className="whitetile-placeholder__item marg-right-15 marg-top-15">
            <header className="home-white-tile-header__placeholder"></header>
            <div className="spinner__svg">
            {svgCode}
            </div>
          </div>
        )
      }
      items.push(
        <div key={i} className="placeholder-row">
          {rowItems}
        </div>
      );
    }
    return items
  };

  return (
    <div className="whitetile-placeholder container">
      <div className="whitetile-placeholder__items">
        {renderPlaceholderItems()}
      </div>
    </div>
  );
};

export default WhiteTilePlaceholder
